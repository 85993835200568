<template>
  <div>
    <NavbarCommon :title="title"></NavbarCommon>
    <PapaerInfo :Errorlist="Errorlist"></PapaerInfo>
  </div>
</template>

<script>
import NavbarCommon from '@/components/common/NavbarCommon'
import PapaerInfo from '@/components/course/PapaerInfo'

export default ({
  data () {
    return {
      //   title: '', singlenum
      type: 1
    }
  },
  components: {
    NavbarCommon, PapaerInfo
  },
  beforeMount () {
    this.$store.commit('hideShowTabbar', false)
  },
  computed: {
    paper: {
      get () {
        return this.$store.state.study.Errorlist
      },
      set () { }

    },
    title () {
      return '错题集 ： ' + this.$store.state.study.paper.pointname
    },
    testinfo () {
      let localTestInfo = this.$store.state.study.testinfo
      if (localTestInfo.length > 0) {
        return localTestInfo
      } else {
        localTestInfo = localStorage.getItem('TestInfo')
      }
      var TestInfoArr = []
      if (localTestInfo !== null) {
        TestInfoArr = JSON.parse(localTestInfo)
      }
      return TestInfoArr
    },
    Errorlist: {
      get () {
        let localPapers = this.$store.state.study.Errorlist
        if (localPapers.length > 0) {
          return localPapers
        } else {
          localPapers = localStorage.getItem('Errorlist')
        }
        var localPapersArr = []
        if (localPapers !== null) {
          localPapersArr = JSON.parse(localPapers)
        }
        return localPapersArr
      },
      set () {

      }

    }

  },
  methods: {
    searchValue (pointid, papers) {
      var paper = []
      for (let i = 0; i < papers.length; i++) {
        // console.log(papers[i].submit)
        // console.log(pointid)
        console.log(papers[i].submit)
        if (papers[i].pointid === pointid && !papers[i].submit) {
          //   console.log('条件为真')
          paper = papers[i]
          //   console.log(paper, '条件为真')
        }
      }
      return paper
    }
  },
  mounted () {
    this.$store.commit('getPaperQuestionInfoMutation', [])
    this.$store.commit('isClick', [])
    this.uid = localStorage.getItem('uid')
    this.TestPointid = localStorage.getItem('TestPointid')
    this.courseid = localStorage.getItem('StudyCourseId')
    // 试题总量
    if ((this.$store.state.study.pointQuestionNum.pointid !== this.TestPointid) && this.getNumOfPoint) {
      this.getNumOfPoint = false
      this.$store.dispatch('getNumOfQuestions', {
        uid: this.uid,
        TestPointid: this.TestPointid,
        getType: 'ONLY',
        courseid: 0
      })
    }
    console.log(this.$store.state.study.pointQuestionNum, 'pointQuestionNum')
    var nowpaper = this.searchValue(this.TestPointid, this.papers)
    // 没有试题 则不抽
    // if (this.paper.total.singlenum + this.paper.total.multiplenum + this.paper.total.subjectivenum > 0) {
    // console.log(nowpaper, 'paper')
    // 试题取回来之前 先清缓存
    this.$store.commit('makePaperMutation', [])
    // 测试 抽题 抽完后保存在本地
    if (nowpaper.pointid > 0) {
      //   console.log('本地数据')
      this.$store.commit('makePaperMutation', nowpaper)
      //   alert('paper为空')
    } else {
      //   console.log('重新请求')
      this.$store.dispatch('makePaper', {
        uid: this.uid,
        TestPointid: this.TestPointid,
        courseid: this.courseid,
        getType: this.type // 1 知识点 2 课程 makePaper
      })
    }
    // }
  },
  beforeUnmount () {
    this.$store.commit('hideShowTabbar', true)
  }
})
</script>
